import React from 'react'
import { Helmet } from 'react-helmet'
const SeoAbout = () => {
  return (
<Helmet>
<meta name='robots' content='index, follow, max-image-preview:large, max-snippet:-1, max-video-preview:-1' />

{/* <!-- Primary Meta Tags --> */}
<title>About Alysei - Italian Food & Beverage Solutions</title>
<meta name="description" content="Alysei, the premier platform connecting Italian food and beverage brands with global markets. Explore our services and expertise in supporting B2B & B2C businesses in the USA and beyond." />
<meta name="keywords" content="Alysei About, Alysei About Us, Alysei" />
<link rel="canonical" href="https://alysei.com/about" />

{/* <!-- Open Graph / Facebook --> */}
<meta property="og:type" content="website" />
<meta property="og:title" content="About Alysei - Italian Food & Beverage Solutions" />
<meta property="og:description" content="Alysei, the premier platform connecting Italian food and beverage brands with global markets. Explore our services and expertise in supporting B2B & B2C businesses in the USA and beyond." />
<meta property="og:url" content="https://alysei.com/about" />
<meta property="og:site_name" content="Alysei" />
<meta property="og:image" content="https://alysei.com/dist/images/office-locations.png" />

{/* <!-- Twitter --> */}
<meta property="twitter:card" content="summary" />
<meta property="twitter:url" content="https://alysei.com/about" />
<meta property="twitter:title" content="About Alysei - Italian Food & Beverage Solutions" />
<meta property="twitter:description" content="Alysei, the premier platform connecting Italian food and beverage brands with global markets. Explore our services and expertise in supporting B2B & B2C businesses in the USA and beyond." />
<meta property="twitter:image" content="https://alysei.com/dist/images/office-locations.png" />

</Helmet>
  )
}

export default SeoAbout