import React from 'react'
import { Helmet } from 'react-helmet'

const VoiceExperts = () => {
  return (
   <Helmet>
    {/* <!-- Primary Meta Tags --> */}
<title>Register as Voice of Experts USA | Alysei</title>
<meta name="description" content="Become a part of Alysei as a 'Voice of Experts' to share your insights, knowledge, and expertise on Italian food and beverage with a global audience." />
<meta name="keywords" content="register as Voice of Experts USA, Italian food expert USA, Italian beverage expert USA, share Italian food knowledge, expert insights on Italian products, Alysei food and beverage experts, global audience food experts, food industry experts USA, voice of experts Italian food, Italian culinary expertise USA" />
<link rel="canonical" href="https://alysei.com/register/voice-of-experts" />

{/* <!-- Open Graph / Facebook --> */}
<meta property="og:type" content="website" />
<meta property="og:title" content="Register as Voice of Experts USA | Alysei" />
<meta property="og:description" content="Become a part of Alysei as a 'Voice of Experts' to share your insights, knowledge, and expertise on Italian food and beverage with a global audience." />
<meta property="og:url" content="https://alysei.com/register/voice-of-experts" />
<meta property="og:site_name" content="Alysei" />
<meta property="og:image" content="https://alysei.com/dist/images/app-mockup.png" />

{/* <!-- Twitter --> */}
<meta property="twitter:card" content="summary" />
<meta property="twitter:url" content="https://alysei.com/register/voice-of-experts" />
<meta property="twitter:title" content="Register as Voice of Experts USA | Alysei" />
<meta property="twitter:description" content="Become a part of Alysei as a 'Voice of Experts' to share your insights, knowledge, and expertise on Italian food and beverage with a global audience." />
<meta property="twitter:image" content="https://alysei.com/dist/images/app-mockup.png" />


   </Helmet>
  )
}

export default VoiceExperts