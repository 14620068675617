import React from 'react'
import { Helmet } from 'react-helmet'
const Voyagers = () => {
    return (
       <Helmet>
        {/* <!-- Primary Meta Tags --> */}
<title>Best Italian Cuisine & Culinary Adventures USA | Alysei</title>
<meta name="description" content="Join a vibrant community dedicated to Italian gastronomy with Alysei. Explore authentic recipes, cooking tips, food photography, and stay updated on Italian food festivals and trends for an unforgettable culinary journey." />
<meta name="keywords" content="Italian Culinary Voyagers, Italian Gastronomy Exploration USA, Italian Cuisine Enthusiasts Platform, Italian Gastronomic Experiences, Italian Food Tourism USA, Culinary Travelers Italy, Italian Food and Beverage Voyages, Italian Gastronomy Community USA" />
<link rel="canonical" href="https://alysei.com/services/voyagers" />

{/* <!-- Open Graph / Facebook --> */}
<meta property="og:type" content="website" />
<meta property="og:title" content="Best Italian Cuisine & Culinary Adventures USA | Alysei" />
<meta property="og:description" content="Join a vibrant community dedicated to Italian gastronomy with Alysei. Explore authentic recipes, cooking tips, food photography, and stay updated on Italian food festivals and trends for an unforgettable culinary journey." />
<meta property="og:url" content="https://alysei.com/services/voyagers" />
<meta property="og:site_name" content="Alysei" />
<meta property="og:image" content="https://alysei.com/dist/images/voyager-contentimg.png" />

{/* <!-- Twitter --> */}
<meta property="twitter:card" content="summary" />
<meta property="twitter:url" content="https://alysei.com/services/voyagers" />
<meta property="twitter:title" content="Best Italian Cuisine & Culinary Adventures USA | Alysei" />
<meta property="twitter:description" content="Join a vibrant community dedicated to Italian gastronomy with Alysei. Explore authentic recipes, cooking tips, food photography, and stay updated on Italian food festivals and trends for an unforgettable culinary journey." />
<meta property="twitter:image" content="https://alysei.com/dist/images/voyager-contentimg.png" />

       </Helmet>
    )
}
export default Voyagers