import React from 'react'
import { Helmet } from 'react-helmet'
const SeoLogin = () => {
  return (
   <Helmet>
    <meta name='robots' content='index, follow, max-image-preview:large, max-snippet:-1, max-video-preview:-1' />

{/* <!-- Primary Meta Tags --> */}
<title>Log In to Alysei - Connect with Italian Food & Beverage Experts USA</title>
<meta name="description" content="Sign in to Alysei and access the leading platform for Italian food and beverage enthusiasts, businesses, and professionals in the USA. Connect and grow your network today!" />
<meta name="keywords" content="Alysei Login, Sign In to Alysei, Alysei Member Login" />
<link rel="canonical" href="https://alysei.com/login" />

{/* <!-- Open Graph / Facebook --> */}
<meta property="og:type" content="website" />
<meta property="og:title" content="Log In to Alysei - Connect with Italian Food & Beverage Experts USA" />
<meta property="og:description" content="Sign in to Alysei and access the leading platform for Italian food and beverage enthusiasts, businesses, and professionals in the USA. Connect and grow your network today!" />
<meta property="og:url" content="https://alysei.com/login" />
<meta property="og:site_name" content="Alysei" />
<meta property="og:image" content="https://alysei.com/dist/images/login-img.png" />

{/* <!-- Twitter --> */}
<meta property="twitter:card" content="summary" />
<meta property="twitter:url" content="https://alysei.com/login" />
<meta property="twitter:title" content="Log In to Alysei - Connect with Italian Food & Beverage Experts USA" />
<meta property="twitter:description" content="Sign in to Alysei and access the leading platform for Italian food and beverage enthusiasts, businesses, and professionals in the USA. Connect and grow your network today!" />
<meta property="twitter:image" content="https://alysei.com/dist/images/login-img.png" />

   </Helmet>
  )
}

export default SeoLogin