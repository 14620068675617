import React from 'react'
import { Helmet } from 'react-helmet'
const Buyers = () => {
  return (
   <Helmet>
    {/* <!-- Primary Meta Tags --> */}
<title>Register as a Buyer USA | Alysei</title>
<meta name="description" content="Sign up as a buyer on Alysei to access a wide range of authentic Italian food and beverage products. Find the best suppliers and grow your business in the USA." />
<meta name="keywords" content="register as buyer USA, Italian food buyers USA, Italian beverage buyers USA, food suppliers USA, beverage suppliers USA, authentic Italian products USA, Alysei food and beverage marketplace, Italian product buyers, grow your business USA, find Italian suppliers USA" />
<link rel="canonical" href="https://alysei.com/register/buyers" />

{/* <!-- Open Graph / Facebook --> */}
<meta property="og:type" content="website" />
<meta property="og:title" content="Register as a Buyer USA | Alysei" />
<meta property="og:description" content="Sign up as a buyer on Alysei to access a wide range of authentic Italian food and beverage products. Find the best suppliers and grow your business in the USA." />
<meta property="og:url" content="https://alysei.com/register/buyers" />
<meta property="og:site_name" content="Alysei" />
<meta property="og:image" content="https://alysei.com/dist/images/app-mockup.png" />

{/* <!-- Twitter --> */}
<meta property="twitter:card" content="summary" />
<meta property="twitter:url" content="https://alysei.com/register/buyers" />
<meta property="twitter:title" content="Register as a Buyer USA | Alysei" />
<meta property="twitter:description" content="Sign up as a buyer on Alysei to access a wide range of authentic Italian food and beverage products. Find the best suppliers and grow your business in the USA." />
<meta property="twitter:image" content="https://alysei.com/dist/images/app-mockup.png" />

   </Helmet>
  )
}

export default Buyers